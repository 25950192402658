import { createRouter, createWebHistory } from 'vue-router';
import routesPT from '../core/routes';
import LandingView from '../views/baz_landing/LandingView.vue';

const routes = [
  {
    path: '/',
    name: 'landing',
    component: LandingView,
  },
  {
    path: routesPT.ROUTES.tutorAccountStatement,
    name: 'tutor',
    component: () => import('@/views/baz_statement-account/MainStatementAccountView.vue'),
  },
  {
    path: routesPT.ROUTES.pagos,
    name: 'pagos',
    component: () => import('@/core/components/DeepLinkView.vue'),
  },
  {
    path: routesPT.ROUTES.pagoqr,
    name: 'pagoqr',
    component: () => import('@/core/components/DeepLinkView.vue'),
  },
  {
    path: routesPT.ROUTES.registroqr,
    name: 'registroqr',
    component: () => import('@/core/components/DeepLinkView.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('@/core/components/NotFoundView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
