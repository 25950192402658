<template>
  <div class="container-super desktop">
    <div class="container-baz">
      <div class="content-icon">
        <img src="@/assets/icons/logoBienestar.png" alt="logo_bienestar_azteca" class="logo-baz">
      </div>
      <section class="container-left">
        <div class="container-avise">
          <div class="content-principal">
            <h1 class="bienestar-important">¡Aviso importante!</h1>
            <p>
              A partir de ahora deberás hacer tus operaciones <br>
              desde la app de  Bienestar Azteca.
            </p>
            <p>
              Si no puedes descargarla, no te preocupes. <br>
              Comunícate con nosotros:
            </p>
            <p class="atencion-bienestar">
              <a href="mailto:atencionaclientesbienestarazteca@bancoazteca.com">
                atencionaclientesbienestarazteca@bancoazteca.com</a>
            </p>
            <h2 class="download-now">¡Descárgala ya!</h2>
            <section class="container-store">
              <a href="https://play.google.com/store/apps/details?id=com.bancoazteca.bienestarazteca&hl=es_MX">
                <img class="store-bienestar-app" src="@/assets/images/playStore.png"
                alt="bienestar-play_store">
              </a>
              <a href="https://apps.apple.com/mx/app/bienestar-azteca/id1508202835">
                <img class="store-bienestar-app" src="@/assets/images/appleStore.png"
                alt="bienestar-app_store">
              </a>
              <a href="https://appgallery.huawei.com/app/C103425669">
                <img class="store-bienestar-app" src="@/assets/images/appGallery.png"
                alt="bienestar-app_gallery">
              </a>
            </section>
          </div>
        </div>
      </section>
      <aside class="container-right">
        <img src="https://d1mq21xo4bjiqa.cloudfront.net/images-portal-tutores/Phone/Phone.png"
          alt="telefono_bienestar" class="phone-bienestar" />
      </aside>
      <footer class="footer-wave">
        <svg viewBox="0 0 500 150" preserveAspectRatio="none" class="svg-wave">
          <path d="M-11.00,149.52 C149.99,150.00 350.73,-107.05 521.72,172.22 L500.00,
            150.00 L0.00,150.00 Z">
          </path>
        </svg>
      </footer>
    </div>
    <div>
      <div class="footer-onda">
        <div class="content-tutor">
          <div class="content-consulta-tutor">
            <h2  class="tutor_question">
              ¿Eres padre, madre ó tutor? <br>
              <p>
                Consulta los estados de cuenta y movimientos del
                menor.</p>
            </h2>
          </div>
        </div>
        <div class="content-status-account">
          <div class="content-account">
            <button class="btn-success" @click="redirect">
              <span class="text-movements">
                ESTADO DE CUENTA Y MOVIMIENTOS
                <img src="@/assets/icons/arrowRight.png" alt="bienestar-estado_de_cuenta"
                  class="arrow-right" />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="container-need-help">
        <NeedHelpAviseView :iconAvise="iconAvise"/>
      </div>
    </div>
  </div>
  <div class="container-super mobile over-mobile">
    <section class="container-baz-mobile">
      <div class="container-wave-mobile">
      <div class="container-icon-baz-mobile">
        <img src="@/assets/icons/logoBienestar.png" alt="logo_bienestar_azteca"
        class="logo-baz-mobile">
      </div>
      <div class="container-phone-mobile">
        <div class="conten-phone-mobile">
          <img src="https://d1mq21xo4bjiqa.cloudfront.net/images-portal-tutores/Phone/Phone.png" width="80%" height="90%"
          alt="telefono_bienestar" class="phone-bienestar" />
        </div>
      </div>
      <section class="container-avise-movile">
        <h1 class="avise-important-movile">¡Aviso importante!</h1>
        <div class="content-principal">
          <p class="white">
            A partir de ahora deberás hacer tus operaciones <br>
            desde la app de  Bienestar Azteca.
          </p>
          <p class="white">
            Si no puedes descargarla, no te preocupes. <br>
            Comunícate con nosotros:
          </p>
          <p class="atencion-bienestar">
            <a href="mailto:atencionaclientesbienestarazteca@bancoazteca.com">
              atencionaclientesbienestarazteca@bancoazteca.com</a>
          </p>
          <h2 class="download-now white">¡Descárgala ya!</h2>
        </div>
        <section class="container-store">
          <a href="https://play.google.com/store/apps/details?id=com.bancoazteca.bienestarazteca&hl=es_MX">
            <img class="store-bienestar-app" src="@/assets/images/playStore.png"
            alt="bienestar-play_store">
          </a>
          <a href="https://apps.apple.com/mx/app/bienestar-azteca/id1508202835">
            <img class="store-bienestar-app" src="@/assets/images/appleStore.png"
            alt="bienestar-app_store">
          </a>
          <a href="https://appgallery.huawei.com/app/C103425669">
            <img class="store-bienestar-app" src="@/assets/images/appGallery.png"
            alt="bienestar-app_gallery">
          </a>
        </section>
      </section>
      <div>
        <svg viewBox="0 0 500 150" preserveAspectRatio="none" class="svg-wave">
          <path d="M-14.44,30.01 C150.33,117.61 333.74,-97.93 516.02,
            123.51 L500.00,149.60 L-0.00,149.60 Z">
          </path>
        </svg>
      </div>
      </div>
      <div class="container-tutor-mobile">
        <div class="content-tutor-mobile">
          <h2 class="text-is-tutor">
            ¿Eres padre, madre ó tutor? <br>
            <p class="text-consulta-mobile">
              Consulta los estados de cuenta y movimientos del
              menor.</p>
          </h2>
        </div>
        <div class="container-movements-mobile">
          <div class="content-movements-mobile">
            <button class="btn-success" @click="redirect">
              <span>
                ESTADO DE CUENTA Y MOVIMIENTOS
                <img src="@/assets/icons/arrowRight.png" alt="bienestar-estado_de_cuenta"
                  class="arrow-right" />
              </span>
            </button>
          </div>
            <NeedHelpAviseView :iconAvise="iconAvise"/>
          </div>
        </div>
      </section>
    </div>
</template>

<script async setup>
import { useRouter } from 'vue-router';
import routes from '@/core/routes';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import icons from '@/core/constants/icons';
import NeedHelpAviseView from '@/core/components/NeedHelpAviseView.vue';

const iconAvise = ref([{}]);
const router = useRouter();
function redirect() {
  router.push(routes.ROUTES.tutorAccountStatement);
}

onMounted(() => {
  iconAvise.value = icons.icons;
  if (useStore().state.spinnerShow) {
    useStore().dispatch('hideSpinner');
  }
});

</script>

<style scoped>

.mobile {
  display: none;
}
.over-mobile {
  overflow: auto;
}
.container-baz-mobile {
  width: 100%;
  overflow: auto !important;
}
.container-wave-mobile {
  width: 100%;
  background-image: radial-gradient(circle at 0% -30%, #009abd,rgba(9, 122, 148, 0) 93%),
  linear-gradient(136deg, #24d28f -52%, #94d060 9%, #9fff02 68%);
}
.container-icon-baz-mobile {
  width: 100%;
  text-align: left;
}
.logo-baz-mobile {
  width: 55px;
  height: 50px;
  margin-left: 20px;
  margin-top: 20px;
}
.container-phone-mobile {
  width: 90%;
  margin: auto;
  margin-top: -20px;
  padding: 0px;
}
.conten-phone-mobile {
  width: 100%;
}
.container-tutor-mobile {
  background-color: #fff;
  margin-top: -20px;
}
.content-tutor-mobile {
  margin: auto;
  text-align: center;
  padding: 0px 5px;
  margin-top: 0px;
}
.text-consulta-mobile {
  font-weight: normal;
}
.container-movements-mobile {
  margin-top: 20px;
  padding-bottom: 20px;
}
.content-movements-mobile {
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.help-information {
  font-size: 35px;
}
h2 {
  color: #fff;
}
.text-movements {
  font-size: 16px;
}
.tutor_question {
  color: #000;
  text-align: justify;
  font-size: 20px;
}
.tutor_question p {
  font-weight: normal;
}
.bienestar-important {
  color: white;
  font-size: 40px;
  text-align: left;
}
.container-baz {
  width: 100%;
  background-image: radial-gradient(circle at 0% -30%, #009abd,
  rgba(9, 122, 148, 0) 93%), linear-gradient(136deg, #24d28f -52%, #94d060 9%, #9fff02 68%);
  display: flex;
  justify-content: space-around;
  padding: 0px !important;
  margin: 0px !important;
  position: relative;
}

.svg-wave {
  height: 100%;
  width: 100%;
}

.svg-wave path {
  stroke: none;
  fill: #fff;
}

.logo-baz {
  width: 65px;
  max-width: 70px;
  height: 60px;
  max-height: 65px;
  margin-left: 30px;
  margin-top: 30px;
}

.content-icon {
  width: 100%;
  text-align: left;
  position: absolute;
}
.container-store {
  margin-top: 10px;
  display: flex;
  justify-content: baseline;
  margin-bottom: 100px;
}
.store-bienestar-app {
  cursor: pointer;
  width: auto;
  height: 40px;
  padding: 0px 5px;
  max-width: 150px;
  max-height: 50px;
}
.container-avise {
  width: 500px;
  margin-top: 65px;
}
.container-avise .content-principal {
  width: 500px;
  display: inline-block;
}
.container-avise p {
  text-align: justify;
  margin-top: 20px;
  color: #fff !important;
}
.atencion-bienestar {
  padding: 0px 3px;
  word-break:break-all;
}
.atencion-bienestar a {
  color: #fff;
}
.download-now {
  font-weight: 600;
  text-align: left;
  font-size: 30px;
  margin-top: 30px;
}
.footer-onda {
  width: 100%;
  height: auto;
  display: flex;
  padding: 10px 0px;
}
.footer-wave {
  height: 15.2%;
  position: absolute;
  bottom: -2px;
  overflow: hidden;
  width: 100%;
  border-color: none;
}
.footer-onda .content-tutor {
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 30px !important;
}
.footer-onda .content-tutor .content-consulta-tutor {
  height: 80%;
  margin: auto;
  display: grid;
  width: 500px;
  align-items: inherit;
}
.phone-bienestar {
  max-width: 90%;
  max-height: 90%;
  width:auto;
  height: auto;
}
.btn-success {
  height: 50px;
  width: auto;
  max-width: 400px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: #0c825e;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  height: auto;
}
.btn-success span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-status-account {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 45%;
  height: 100%;
  padding: 0 30px;
}
.content-status-account .content-account {
  height: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-right{
  width: 20px;
  margin-left: 10px;
}

.container-left {
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 30px !important;
}
.container-right {
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 4px 0px 0px;
  padding: 0px 30px;
}

/*mobile class*/
.container-avise-movile {
  padding: 0px 15px 15px 15px;
  margin-top: -15px;
}

.container-avise-movile .avise-important-movile {
  color: white;
  font-size: 25px;
}
.container-avise-movile .avise-important-movile .content-principal p{
  color: white !important;
}
.white {
  color: white;
}

.footer-wave {
  overflow: hidden;
}

/* mobile*/
.text-is-tutor {
  font-size: 20px;
  color: #000;
  font-size: 20px;
}
.text-is-tutor p {
  font-weight: normal;
  width: 80%;
  font-size: 14px;
  margin: auto;
}

.container-need-help {
  margin-top: 50px;
  width: 100%;
  position: absolute;
}
@media only screen and (min-width: 741px) and (max-width: 1400px)
  and (min-height:400px) and (max-height: 740px) {
    /* laptop vs media desktop ✔*/
    .mobile {
      display: none;
    }
    .desktop {
      display: grid;
    }
    .bienestar-important {
      font-size: 25px !important;
    }
    .content-principal p {
      font-size: 14px !important;
    }
    .atencion-bienestar a {
      font-size: 13px !important;
    }
    .download-now {
      margin-top: 10px !important;
      font-size: 20px !important;
    }
    .container-store {
      display: flex;
    }
    .store-bienestar-app {
      width: 100px !important;
    }
    .tutor_question {
      font-size: 20px !important;
    }
    .tutor_question p {
      font-size: 16px !important;
    }
    .text-movements {
      font-size: 14px !important;
    }
}
@media only screen and (max-width: 800px) and (min-height: 450px) and (max-height: 750px) {
  .mobile {
    display: none;
  }
  .desktop {
    display: grid;
  }
}
@media only screen and (max-width: 1024px) and (max-height: 980px) {
  /* 1/2 display ✔*/
  .mobile {
    display: none;
  }
  .desktop {
    display: grid;
  }
  .bienestar-important {
    font-size: 30px;
  }
  .container-avise .content-principal p {
    font-size: 16px;
  }
  .atencion-bienestar a{
    font-size: 15px;
  }
  .download-now {
    font-size: 25px;
  }
  .tutor_question {
    font-size: 20px;
  }
  .tutor_question p {
    font-size: 16px;
  }
  .text-movements {
    font-size: 13px;
  }
  .container-left {
    /*margin-left: 95px;*/
    display: flow-root;
    margin-top: 90px;
  }
  .child {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - 20px);
  }
}
@media only screen and (min-width : 1024px) {
  .bienestar-important {
    font-size: 40px;
  }
  .desktop {
    display: grid;
  }
  .mobile {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px)
  and (orientation: landscape) {
  .mobile {
    display: none;
  }
  span {
    font-size: 14px;
  }
  .content-principal p {
    font-size: 14px;
  }
  .container-avise {
    margin-top: 50px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 530px) {
  .desktop{
    display: none;
  }
  .mobile {
    display: grid;
  }
  .atencion-bienestar a{
    font-size: 14px;
  }
  .content-principal p {
    font-size: 14px;
    margin-top: 15px;
  }
  .phone-bienestar {
    width: 350px;
    height: 300px;
  }
  .download-now {
    font-size: 20px;
    text-align: center;
    margin: auto;
    margin-top: 10px;
  }
  .text-is-tutor {
    font-size: 20px;
    margin-top: -40px;
  }
  .text-is-tutor p {
    font-size: 14px;
  }
  .btn-success span {
    font-size: 12px;
  }
  .download-now {
    margin-top: 25px;
  }
  .container-store {
    margin-bottom: 0px;
    display: flow-root;
  }
}
@media only screen and (min-width: 350px) and (max-width: 400px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .atencion-bienestar a{
    font-size: 14px;
  }
  .content-principal p {
    font-size: 14px;
    margin-top: 15px;
  }
  .phone-bienestar {
    width: 350px;
    height: 300px;
  }
  .download-now {
    font-size: 20px;
    text-align: center;
    margin: auto;
    margin-top: 10px;
  }
  .text-is-tutor {
    font-size: 20px;
    margin-top: -40px;
  }
  .text-is-tutor p {
    font-size: 14px;
  }
  .btn-success span {
    font-size: 12px;
  }
  .download-now {
    margin-top: 25px;
  }
  .container-store {
    margin-bottom: 0px;
    display: flow-root;
  }
}
@media only screen and (min-width: 567px) and (max-width: 1025px)
  and (min-height: 300px) and (max-height: 400px)
    and (orientation: landscape) {

      .mobile {
        display: none;
      }
      .desktop {
        display: grid;
      }
      .container-baz {
        display: inline-flex;
        height: auto;
        padding-bottom: 20px !important;
      }

      .container-left .container-avise {
        display: flow-root;
        justify-content: first baseline;
      }

      .container-avise .bienestar-important {
        font-size: 25px;
      }

      .container-right {
        display: flex;
        width: 45%;
        justify-content: flex-end;
        align-items: center;
      }
      .phone-bienestar {
        width: 80%;
      }
      .content-principal p {
        font-size: 20px;
      }

      .container-store {
        display: flex;
      }

      .footer-onda{
        width: 100%;
        margin: auto;
        padding: 0px;
        height: auto !important;
        padding-bottom: 15px;
      }

      .atencion-bienestar a {
        font-size: 16px;
      }
      .container-baz .content-icon {
        display: contents;
      }
      .download-now {
        font-size: 20px;
      }
      .content-tutor h2 {
        font-size: 20px;
      }

      .content-tutor p {
        font-size: 20px;
      }
      .text-is-tutor p {
        width: auto;
      }
}
@media only screen and (min-width: 650px) and (max-width: 668px)
  and (min-height: 300px) and (max-height: 380px) and (orientation: landscape) {
    /* ✔*/
    .mobile {
      display: none;
    }
    .desktop {
      display: grid;
    }
    .container-left {
      width: 45%;
      margin-top: 100px;
    }
    .container-right {
      width: 55% !important;
    }
    .bienestar-important {
      font-size: 22px !important;
    }
    p {
      font-size: 12px !important;
    }
    .download-now {
      font-size: 19px;
    }
    .footer-onda .content-tutor h2 {
      font-size: 16px !important;
    }
    .footer-onda .content-tutor h2 p {
      font-size: 14px !important;
    }
    a {
      font-size: 15px;
    }
    span {
      font-size: 10px;
    }
    button span {
      font-size: 12px;
    }
}
/*--*/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)
  and (orientation: portrait) {
    .mobile {
      display: none;
    }
    .container-baz {
      display: flex;
    }
    .container-left {
      width: 45%;
      display: block;
      margin-top: 140px;
    }
    .content-tutor {
      width: 45% !important;
    }
    .content-status-account {
      width: 45%;
      padding: 0px 0px 0px 30px;
    }
    .container-right {
      width: 45%;
    }
    .container-store {
      margin-bottom: 0px;
      display: flex;
    }
    .btn-success {
      max-width: 250px;
    }

}
@media only screen and (min-device-width: 640px) and (max-device-width: 670px)
  and (min-device-height: 360px) and (max-device-height: 380px) and (orientation: landscape) {
    .mobile {
      display: none;
    }
    .container-left {
      width: 55% !important;
      margin-left: -95px;
    }
    .container-right {
      width: 45% !important;
      justify-content: flex-start;
    }
    .container-avise {
      width: auto;
    }
    .container-store {
      display: block;
      margin-bottom: 60px;
    }
    .content-principal {
      width: auto !important;
      display: block !important;
    }
    .content-tutor {
      width: 45% !important;
    }
    .content-status-account {
      display: grid;
      padding: 0px;
      justify-content: flex-start;
    }
    .btn-success {
      max-width: 240px;
    }
    .footer-onda {
      overflow: auto;
    }

}
@media only screen and (device-width: 600px) and (device-height: 960px)
  and (orientation: portrait) {
  .container-left {
    width: 55% !important;
  }
  .container-right {
    width: 45% !important;
    justify-content: flex-start;
  }
  .container-avise {
    width: auto;
  }
  .container-store {
    display: flex;
    margin-bottom: 60px;
  }
  .content-principal {
    width: auto !important;
    display: block !important;
  }
  .content-tutor {
    width: 45% !important;
  }
  .content-status-account {
    display: grid;
    padding: 0px;
    justify-content: flex-start;
  }
  .btn-success {
    max-width: 240px;
  }
  .footer-onda {
    overflow: auto;
  }
  .store-bienestar-app {
    width: 80px !important;
  }
}
@media only screen and (min-device-width: 740px) and (max-device-width: 851px)
  and (min-device-height: 353px) and (max-device-height: 393px)
    and (orientation: landscape) {
      .container-left {
        width: 55% !important;
        margin-left: -95px;
      }
      .container-right {
        width: 45% !important;
        justify-content: center;
      }
      .container-avise {
        width: auto;
      }
      .container-store {
        display: flex;
        margin-bottom: 60px;
      }
      .content-principal {
        width: auto !important;
        display: block !important;
      }
      .content-tutor {
        width: 45% !important;
      }
      .content-status-account {
        display: grid;
        padding: 0px;
      }
      .btn-success {
        max-width: 240px;
      }
      .footer-onda {
        overflow: auto;
      }
      .store-bienestar-app {
        width: 110px !important;
      }
}
@media only screen and (device-width: 568px) and (device-height: 320px)
    and (orientation: landscape) {
      .container-left {
        width: 45% !important;
        margin-left: -95px;
      }
      .container-right {
        width: 45% !important;
        justify-content: center;
      }
      .container-avise {
        width: auto;
      }
      .container-store {
        display: flex;
        margin-bottom: 60px;
      }
      .content-principal {
        width: auto !important;
        display: block !important;
      }
      .content-tutor {
        width: 45% !important;
      }
      .content-status-account {
        display: grid;
        padding: 0px;
      }
      .btn-success {
        max-width: 240px;
      }
      .footer-onda {
        overflow: auto;
      }
      .store-bienestar-app {
        width: 80px !important;
      }
}
@media only screen and (min-device-width: 920px) and (max-device-width: 950px)
  and (min-device-height: 300px) and (max-device-height: 450px)
    and (orientation: landscape) {
      .container-left {
        margin-left: -95px;
      }
    }

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .container-left {
    margin-left: -95px;
  }

}
@supports not (-webkit-touch-callout: none) {
}

</style>
