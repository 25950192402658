const icons = [{
  nombre: 'Recuperación de usuario',
  url: 'https://d1mq21xo4bjiqa.cloudfront.net/images-portal-tutores/manuales/baz_recuperaUsuario.svg',
  pdf: 'https://d1mq21xo4bjiqa.cloudfront.net/advertising/pdfs/01_PS_RecuperacionUsuario.pdf',
}, {
  nombre: 'Recuperación de contraseña',
  url: 'https://d1mq21xo4bjiqa.cloudfront.net/images-portal-tutores/manuales/baz_recuperaCredencial.svg',
  pdf: 'https://d1mq21xo4bjiqa.cloudfront.net/advertising/pdfs/03_RecuperacionContraseña.pdf',
}, {
  nombre: 'Olvidé mi clave de seguridad',
  url: 'https://d1mq21xo4bjiqa.cloudfront.net/images-portal-tutores/manuales/baz_olvideClaveSeguridad.svg',
  pdf: 'https://d1mq21xo4bjiqa.cloudfront.net/advertising/pdfs/05Tutorial_PS_OlvideClaveSeguridad.pdf',
}, {
  nombre: 'Actualización de clave de seguridad',
  url: 'https://d1mq21xo4bjiqa.cloudfront.net/images-portal-tutores/manuales/baz_actualizaClave.svg',
  pdf: 'https://d1mq21xo4bjiqa.cloudfront.net/advertising/pdfs/08Tutorial_PS_ActualizacionClaveSeguridad.pdf',
}, {
  nombre: 'Actualización de número celular',
  url: 'https://d1mq21xo4bjiqa.cloudfront.net/images-portal-tutores/manuales/baz_actualizaNumero.svg',
  pdf: 'https://d1mq21xo4bjiqa.cloudfront.net/advertising/pdfs/09Tutorial_PS_ActualizacionCelular.pdf',
}, {
  nombre: 'Actualización de correo',
  url: 'https://d1mq21xo4bjiqa.cloudfront.net/images-portal-tutores/manuales/baz_actualizaCorreo.svg',
  pdf: 'https://d1mq21xo4bjiqa.cloudfront.net/advertising/pdfs/06Tutorial_PS_ActualizacionCorreo.pdf',
}, {
  nombre: 'Cambio de dispositivo',
  url: 'https://d1mq21xo4bjiqa.cloudfront.net/images-portal-tutores/manuales/baz_cambioDispositivo.svg',
  pdf: 'https://d1mq21xo4bjiqa.cloudfront.net/advertising/pdfs/07Tutorial_PS_CambioDispositivo.pdf',
}, {
  nombre: 'Recuperación de cuenta en sucursal',
  url: 'https://d1mq21xo4bjiqa.cloudfront.net/images-portal-tutores/manuales/baz_sucursal.svg',
  pdf: 'https://d1mq21xo4bjiqa.cloudfront.net/advertising/pdfs/04_RecuperacionCuentaSucursal.pdf',
},
];

export default { icons };
