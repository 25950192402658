<template>
  <div class="text-need-help">
    <div class="need-help">
      <h2 class="help-information">
        Necesitas ayuda consulta esta información
      </h2>
    </div>
  </div>
  <div style="margin-top: 50px; display: flex; width: auto;" class="container-information">
    <div class="container-img-chica">
      <img src="https://d1mq21xo4bjiqa.cloudfront.net/images-portal-tutores/manuales/baz_informacionChica.svg"
        alt="aviso_chica_bienestar" class="logo-chica">
    </div>
    <div class="container-icons">
      <div class="parent" style="width: 95%;">
        <div v-for="icon in props.iconAvise" :key="icon" class="child"
          @click="redirectToDocument(icon.pdf);" @keydown="redirectToDocument(icon.pdf);">
          <img :src="icon.url" :alt="icon.nombre" class="img-icon"/>
          <p style="color: #5E5E5E; margin: auto; padding: 0px; font-weight: 500;">
            {{ icon.nombre }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-footer-avise">
    <div style="display: flex; justify-content: center;
      align-items: center; padding: 10px 10px 5px 10px;">
      <img src="https://bienestarazteca-contenidoestatico.s3.amazonaws.com/images-portal-tutores/manuales/baz_telefono.svg"
        alt="baz_telefono_informacion" style="width: 50px; height: 50px;"/>
    </div>
    <div style="display: flex; justify-content: center; align-items: center;">
      <p class="text-information-account">"Si requieres información
        sobre tu cuenta, tienes alguna aclaración o deseas cancelarla, comunícate
        al siguiente teléfono:
        <a href="tel:5576006440" style="text-decoration: none !important;
          font-size: 100%; color:black; font-weight: 500;">5576006440"
        </a>
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { sanitizeUrl } from '@braintree/sanitize-url';

const props = defineProps({
  iconAvise: {},
});
const url = ref('');

function redirectToDocument(urlPdf) {
  try {
    url.value = sanitizeUrl(urlPdf || '');
    window.open(url.value, '_blank', 'noreferrer noopener');
  } catch (error) {
    throw new Error(error);
  }
}
</script>
<style scoped>
.text-need-help {
  width: 100%;
}
.need-help {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-img-chica {
  width: 35%;
  display:flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: -100px;
}
.container-icons {
  display: flex;
  justify-content: center;
  width: 65%;
  margin-left: 100px;
}
.logo-chica {
  min-width: 200px;
  min-height: 200px;
  width: 90%;
}
.parent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.child {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(33.33% - 20px);
  min-height: 180px;
  margin-bottom: 25px;
  height: auto;
  width: 200px;
  cursor: pointer !important;
}
.img-icon {
  min-width: 102px;
  min-height: 73px;
  max-width: 122px;
  max-height: 93px;
  width: auto;
  height: auto;
}
.container-footer-avise {
  margin:auto;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.help-information {
  color: #2D6244;
  font-weight: 600;
  font-size: 30px;
}
.text-information-account {
  text-align: justify;
  font-size: .9rem;
}
@media only screen and (min-width: 741px) and (max-width: 1400px)
  and (min-height:400px) and (max-height: 740px) {
    .text-information-account {
      font-size: .8rem;
      text-align: center;
    }
    .help-information {
      font-size: 20px !important;
    }
}
@media only screen and (max-width: 1024px) and (max-height: 980px) {
    .help-information {
      font-size: 25px !important;
    }
    .text-information-account {
      font-size: .7rem;
    }
    .container-img-chica {
      margin-left: -50px;
    }
    .container-icons {
      margin-left: 50px;
    }
    .container-footer-avise {
      width: 75% !important;
      margin: 0%;
      justify-content: start;
      margin-top: 0px;
      width: calc(100% - 70px) !important;
    }
    .container-img-chica {
      margin-left: -30px;
    }
}
@media only screen and (min-width : 1024px) {
  .text-information-account {
    font-size: .9rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px)
  and (orientation: landscape) {
    .text-information-account {
      font-size: .7rem;
    }
    .container-img-chica {
      width: 30%;
    }

    .container-icons {
      width: 60%;
    }
}
@media only screen and (min-width: 200px) and (max-width: 530px) {
.text-need-help {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  width: 95%;
  margin: auto;
}
.help-information {
  font-size: 20px !important;
}
.container-img-chica {
  justify-content: center;
  width: 60%;
  margin-top: 0px !important;
}
.container-icons {
  width: 100%;
}
.container-information {
  display: block !important;
  width: 100%;
  margin-top: 20px !important;
}
.container-information > * {
  order: 0;
  margin: auto;
  margin-top: 60px;
}
.container-second-information {
  order: 0;
  padding: 0px;
  margin: auto;
  margin-top: 100px;
}
.child {
  flex-grow: 0;
  flex-shrink: 0;;
  flex-basis: calc(50% - 20px);
  cursor: pointer !important;
}
.parent{
  justify-content: space-between !important;
}
.text-information-account {
  font-size: .7rem;
}
.container-footer-avise {
  width: 80%;
  margin: 0%;
  justify-content: start;
  margin-top: 0px;
}
}
@media only screen and (min-width: 567px) and (max-width: 1025px)
  and (min-height: 300px) and (max-height: 400px)
  and (orientation: landscape) {
  .help-information {
    font-size: 20px;
  }
  .text-information-account {
    font-size: .7rem;
    text-align: center;
  }
}

@media only screen and (min-width: 650px) and (max-width: 668px)
  and (min-height: 300px) and (max-height: 380px) and (orientation: landscape) {
    .help-information {
      font-size: 19px;
    }
    .text-information-account {
      font-size: 8px;
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .text-information-account {
      font-size: .7rem;
      text-align: center;
    }
    .container-img-chica {
      margin-left: -50px;
      width: 30%;
    }
    .container-icons {
      margin-left: -10px;
      width: auto;
    }
}
@media only screen and (min-device-width: 640px) and (max-device-width: 670px)
  and (min-device-height: 360px) and (max-device-height: 380px) and (orientation: landscape) {
    .text-information-account {
      font-size: .8rem;
    }
}
@media only screen and (device-width: 600px) and (device-height: 960px)
  and (orientation: portrait) {
    .text-information-account {
      font-size: .8rem;
    }
}
@media only screen and (min-device-width: 740px) and (max-device-width: 851px)
  and (min-device-height: 353px) and (max-device-height: 393px)
    and (orientation: landscape) {
      .text-information-account {
        font-size: .7rem;
      }
}
@media only screen and (device-width: 568px) and (device-height: 320px)
    and (orientation: landscape) {
      .text-information-account {
        font-size: .7rem;
      }
}
@media only screen and (min-device-width: 920px) and (max-device-width: 950px)
  and (min-device-height: 300px) and (max-device-height: 450px)
    and (orientation: landscape) {
      .text-information-account {
        font-size: .7rem;
      }
}
</style>
