<template>
    <GDialog max-width="80%" persistent v-model="showModalError">
      <div class="content-modal">
        <div class="content-close-icon" @click="tryAgain" @keydown="tryAgain">
          <img src="@/assets/icons/closeTr.png" alt="close_modal"
            class="modal-close-icon">
        </div>
        <div class="content-title-error">
          <h3 class="text-title-error"
            v-if="modalProperties.title !== undefined && modalProperties.title !== ''">
            {{ modalProperties?.title }}
          </h3>
          <h3 class="text-title-error" v-else>¡Oh no, espera!</h3>
        </div>
        <div class="content-main">
          <div class="container-warning-icon">
            <img src="https://d1mq21xo4bjiqa.cloudfront.net/images-portal-tutores/Alert_01/Alert_01.png" alt="warning-bienestar_azteca">
          </div>
          <div class="content-main-text">
            <p class="main-text">
              {{ modalProperties.message }}
            </p>
          </div>
          <div class="container-button">
          <button class="btn-tryAgain" @click="tryAgain">
            <span class="btn-text">
              <span>
                {{ modalProperties?.textButton }}
              </span>
              <img src="@/assets/icons/arrowRight.png" alt="bienestar-siguiente"
                class="arrow-right"/>
            </span>
            </button>
          </div>
        </div>
      </div>
    </GDialog>
</template>

<script setup>
import {
  ref,
  watchEffect,
  computed,
}
  from 'vue';

import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const router = useRouter();

const store = useStore();

let showModalError = ref(store.state.paramsModalError.showModal);
let modalProperties = ref(store.state.paramsModalError);

watchEffect(() => {
  showModalError = computed(() => store.state.paramsModalError.showModal);
  modalProperties = computed(() => store.state.paramsModalError);
});

const tryAgain = () => {
  const tempRedirection = modalProperties.value.routeRedirection;
  if (modalProperties.value.routeRedirection !== '') {
    store.dispatch('managerModal', {});
    store.commit('showSpinner');
    setTimeout(() => {
      store.commit('hideSpinner');
      router.push(tempRedirection);
    }, 700);
  } else {
    store.dispatch('managerModal', {});
  }
};

</script>

<style scoped>
.content-modal {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 4px 5px rgba(0, 0, 0, 0.25);
  font-family: 'Poppins';
  position: relative;
}
.modal-close-icon {
  width: 20px;
}
button {
    font-weight: bold;
}
.content-close-icon {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
.content-title-error {
  margin: auto;
  text-align: center;
}
.text-title-error {
  font-size: 40px;
  color: #fd7100;
}
.content-main {
  margin: auto;
  text-align: center;
  margin-top: 50px;
  width: 80%;
}
.content-main-text {
  margin-top: 50px;
  padding: 0px 10px
}
.main-text {
  text-align-last: center;
  text-align: justify;
  font-size: 20px;
}
.container-button {
  margin-top: 50px;
}
.btn-tryAgain {
  margin: auto;
  font-size: 16px;
  width: 143px;
  height: 50px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  border-radius: 20px;
  background-color: #0c825e;
  color: white;
  cursor: pointer;
  outline: none;
  border: none;
}
.container-warning-icon {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.arrow-right{
  width: 20px;
  margin-left: 10px;
}
.container-warning-icon img {
  width: 145px;
  height: 145px;
}
.btn-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-text span {
  font-size: 16px;
}
/* Portrait*/
@media only screen and (min-width: 200px) and (max-width: 530px) {
  .content-main-text .main-text {
    font-size: 14px;
  }
  .text-title-error {
    font-size: 20px;
  }
  .btn-tryAgain {
    font-size: 12px;
  }
  .container-warning-icon img {
    width: 120px;
    height: 120px;
  }
  .btn-text span {
    font-size: 16px;
  }
  .modal-close-icon {
    width: 15px;
  }
}
/* Portrait*/
@media only screen and (min-width: 350px) and (max-width: 400px) {
  .content-main-text .main-text {
    font-size: 14px;
  }
  .text-title-error {
    font-size: 20px;
  }
  .btn-tryAgain {
    font-size: 12px;
  }
  .container-warning-icon img {
    width: 120px;
    height: 120px;
  }
  .btn-text span {
    font-size: 16px;
  }
  .modal-close-icon {
    width: 15px;
  }
}
/* Landscape all*/
@media only screen and (min-width: 567px) and (max-width: 1025px)
  and (min-height: 200px) and (max-height: 730px)
  and (orientation: landscape) {
  .text-title-error {
      font-size: 20px;
    }
    .main-text {
      font-size: 14px;
    }
    .btn-tryAgain {
      font-size: 12px;
    }
    .modal-close-icon {
      width: 24px;
    }
    .container-warning-icon img {
      width: 120px;
      height: 120px;
    }
    .btn-text span {
      font-size: 16px;
    }
    .modal-close-icon {
      width: 15px;
    }
}
</style>
