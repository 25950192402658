import { createApp } from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { GDialog } from 'gitart-vue-dialog';
import { vMaska } from 'maska';
import axios from 'axios';
import ModalView from '@/core/components/ModalView.vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'gitart-vue-dialog/dist/style.css';
import config from '../public/config/config.json';
import environment from './environment/environment';

if (environment.environment.production) {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}

axios.defaults.timeout = 60000;
axios.defaults.headers.post['Content-Type'] = 'application/json;';
axios.defaults.headers.post['Content-Encoding'] = 'charset=UTF-8';
axios.defaults.responseType = 'json';
axios.defaults.responseEncoding = 'charset=UTF-8';
axios.defaults.baseURL = `${environment.environment.apiEndPoint}`;

const app = createApp(App)
  .use(store)
  .use(router)
  .use(ModalView)
  .use(VueReCaptcha, { siteKey: config?.configuration.siteKey })
  .directive('maska', vMaska)
  .component('GDialog', GDialog)
  .component('ModalView', ModalView);
router.isReady()
  .then(() => app.mount('#app'));
