const ENABLEPRODUCTION = true;
const ENDPOINTAPI = ENABLEPRODUCTION;

const CONFIGDEV = null;

const CONFIGPROD = null;

const CONFIGFIREBASE = (ENABLEPRODUCTION) ? CONFIGPROD : CONFIGDEV;

const environment = {
  production: ENABLEPRODUCTION,
  enableLog: !ENABLEPRODUCTION,
  apiEndPoint: (ENDPOINTAPI) ? '/services/' : '/services/',
  firebaseConfigEnv: CONFIGFIREBASE,
};

export default { environment };
