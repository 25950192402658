import axios from 'axios';
import { createStore } from 'vuex';
import errorServiceGeneric from '@/core/constants/messages';
import modelModal from '@/core/models/ModelModal';
import routes from '@/core/routes';

export default createStore({
  state: {
    spinnerShow: false,
    paramsModalError: modelModal.modelModal,
  },
  getters: {
  },
  mutations: {
    showSpinner(state) {
      state.spinnerShow = true;
    },
    updateModalProperties(state, config) {
      state.paramsModalError = config;
    },
    hideSpinner(state) {
      state.spinnerShow = false;
    },
    activityModal(state, values) {
      state.paramsModalError = values;
    },
  },
  actions: {
    showModalWarning({ commit }, configModal = this.state.paramsModalError) {
      commit('updateModalProperties', configModal);
    },
    managerModal({ commit }, values) {
      commit('activityModal', values);
    },
    async callService(store, dataRequest) {
      return new Promise((resolve, reject) => {
        try {
          axios.post(dataRequest?.context, dataRequest?.data).then((response) => {
            resolve(response.data.respuesta);
          }).catch((err) => {
            if (err) {
              this.state.spinnerShow = false;
              const objModal = {
                showModal: true,
                message: `${errorServiceGeneric.errorServiceGeneric}`,
                routeRedirection: routes.ROUTES.landing,
                textButton: 'Salir',
                title: '',
              };
              store.dispatch('showModalWarning', objModal);
              reject(new Error(false));
            }
          });
        } catch (error) {
          this.state.spinnerShow = false;
          const objModal = {
            showModal: true,
            message: `${errorServiceGeneric.errorServiceGeneric}`,
            routeRedirection: routes.ROUTES.landing,
            textButton: 'Salir',
            title: '',
          };
          store.dispatch('showModalWarning', objModal);
          reject(new Error(false));
        }
      });
    },
  },
  modules: {
  },
});
